exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ipa-aco-tsx": () => import("./../../../src/pages/ipa/aco.tsx" /* webpackChunkName: "component---src-pages-ipa-aco-tsx" */),
  "component---src-pages-ipa-gi-tsx": () => import("./../../../src/pages/ipa/gi.tsx" /* webpackChunkName: "component---src-pages-ipa-gi-tsx" */),
  "component---src-pages-ipa-premium-tsx": () => import("./../../../src/pages/ipa/premium.tsx" /* webpackChunkName: "component---src-pages-ipa-premium-tsx" */),
  "component---src-pages-providers-northwell-direct-search-tsx": () => import("./../../../src/pages/providers/northwell-direct-search.tsx" /* webpackChunkName: "component---src-pages-providers-northwell-direct-search-tsx" */),
  "component---src-pages-redirect-page-template-tsx": () => import("./../../../src/pages/redirectPageTemplate.tsx" /* webpackChunkName: "component---src-pages-redirect-page-template-tsx" */)
}

